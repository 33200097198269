import React from 'react'
import Contact from '../components/home/Contact'
import Meta from '../components/common/Meta';

const metaData = {
    meta : {
        meta_title: "Custom VoIP Softphone | WebRTC Softphone Open Source",
        meta_desc: "Discover the ultimate VoIP softphone solution! Our open-source, customizable softphone delivers unparalleled call quality and features across desktop and mobile.",
        meta_keywords: "Custom VoIP Softphone, WebRTC Softphone Open Source, Best VoIP Softphone, Best Open Source Softphone",
    }
}

const CustomSoftphone = () => {
  return (
    <>
    <Meta value={metaData} canonical="https://fonimo.app/custom-voip-softphone" />
    <section className='comn-hero-blk'>
        <div className='comn-hero-inner'>
            <div className='container px-lg-4'>
                <div className='row justify-content-between'>
                    <div className='col-lg-6 hero-content-wr pe-lg-5'>
                        <h1 className='text-white mb-4'>Custom VoIP Softphone with Personalization and Customization Support</h1>
                        <p className='text-white pb-4'>Get the best VoIP Softphone for iPhone, Android, and desktops that are empowered with cutting edge technologies and outstanding features. Enjoy tailored solutions as we have customization and personalization options available, just for YOU!</p>
                        <a href='/contact-us' className='btn btn-secondary-gradient py-sm-3 px-4 px-sm-5'>Get In Touch</a>
                    </div>
                    <div className='col-lg-5 hero-image-wr'>
                        <figure>
                            <img src='/img/custom_voip_1.webp' alt='Custom VOIP Softphone' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='offerings-blk py-lg-4'>
        <div className='offerings-inner py-5'>
            <div className='container px-lg-4'>
            <div className='row align-items-center'>
                    <div className='col-lg-12 pe-5 text-center'>
                        <h2 className='mb-4'>Empowering Benefits Available with White Label Softphone App</h2>
                        <p>We offer custom branding of our best VoIP Softphone and WebRTC Softphone apps to consolidate the revenue generation model. Along with boosted revenue generation, leverage a plethora of benefits by using this best Softphone for Android, iOS, and several other devices.</p>
                    </div>
                </div>
                <div className='row align-items-center justify-content-around'>
                    
                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Unified-Brand-Consistency .svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Unified Brand Consistency</h4>
                                <p>Having a white label Softphone app ensures building a consistent brand impression across all platforms and devices.</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Tailored-to-Business-Needs.svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Tailored to Business Needs</h4>
                                <p>Invest in customization and custom development to align your business goals while reducing the development cycle.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row align-items-center justify-content-around'>
                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Improved-Customer-Engagement.svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Improved Customer Engagement</h4>
                                <p>Become a one-stop shop for all communication needs of your clients by adding a custom VoIP Softphone to offering bouquets.</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Competitive-Advantage.svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Competitive Advantage</h4>
                                <p>Custom branded apps with tailored features and UI can help you stay ahead of your competitors and boost returns.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row align-items-center justify-content-around'>
                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Efficient-Technical-Support.svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Efficient Technical Support</h4>
                                <p>Don’t stress out yourself about technicalities and maintenance as the experts will manage it for you to let you focus on core strengths.</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Boost-ROI.svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Boost ROI</h4>
                                <p>Save resources on development and don’t use WebRTC Softphone open source app, rather get the best ROI with the white label app.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <section className='cta-block'>
        <div className="container py-5">
            <div className='row py-2 align-items-center justify-content-between'>
                <div className="col-lg-8 col-12">
                    <h3 className="text-white pb-5 animated slideInDown">Leave Your Clients Awestruck with the Outstanding Quality of Service Availed by the Best VoIP Softphone.</h3>
                    <a href="/contact-us" target="" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Request a quote</a>
                </div>
                <div className='col-lg-4 col-12'>
                    <figure>
                        <img src='/img/custom_voip_2.webp' alt='Custom VOIP' />
                    </figure>
                </div>
            </div>
        </div>
    </section>

    <section className='features-block mt-4' id="feature" style={{ width: '100%', minHeight: '750px', maxHeight: '1800px' }}>
        <div className="container-xxl py-5">
            <div className="container px-lg-5">
                <div className="text-center wow fadeInUp mb-5" data-wow-delay="0.1s">
                    <h2 className="mb-3">Top Reasons to Use Fonimo: Custom VoIP Softphone</h2>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                </div>
                <div className="row g-4">

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/Cutting-Edge-Technologies.svg" alt="" />
                            </div>
                            <h5 className="mb-3">Cutting Edge Technologies</h5>
                            <p className="m-0">Fonimo is developed with advanced technologies and we keep adopting the technology trends to stay competitive in the industry.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/WebRTC-Support.svg" alt="" />
                            </div>
                            <h5 className="mb-3">WebRTC Support</h5>
                            <p className="m-0">Enjoy the true potential of real time communication with the web as our WebRTC Softphone app supports WebRTC technology and features.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/Custom-Integration.svg" alt="" />
                            </div>
                            <h5 className="mb-3">Custom Integration</h5>
                            <p className="m-0">Build a complete ecosystem of communication offerings by integrating any VoIP or business tool with the Fonimo app.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/Development-and-Customization.svg" alt="" />
                            </div>
                            <h5 className="mb-3">Development and Customization</h5>
                            <p className="m-0">Give wings to your competitive spirit and innovative mind with our custom development services to add novel features.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/Regular-Enhancements.svg" alt="" />
                            </div>
                            <h5 className="mb-3">Regular Enhancements</h5>
                            <p className="m-0">We assure long term association with unique features and technology trend implementation in regular version releases.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/Round-the-Clock-Support.svg" alt="" />
                            </div>
                            <h5 className="mb-3">Round the Clock Support</h5>
                            <p className="m-0">Focus on your core capabilities because our technical experts are available at your disposal with 24/7 technical support.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <section className='download_cta_block my-5'>
        <div className='container'>
            <div className='download_cta_inner text-center p-5'>
                <h3 className='text-white mb-5'>Meet the modern demands of digital customers with the best in the industry Softphone App.</h3>
                <a href="/contact-us" target="" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Try a Free Version</a>
            </div>
        </div>
    </section>


    <section className="why-choose-block">
        <div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="mb-5">FAQ</h3>
                </div>
                <div className="row gy-5 gx-4 justify-content-center">
                <div className="faq-wrap">
                        <div className="faq-set">
                            <h4 className='active'>1. What is a VoIP Softphone? <i className="fas fa-angle-down"></i></h4>
                            <div className="content" style={{display: "block"}}>
                                <p>It is a software application that resembles a phone and provides it as a mobile app to harness the power of VoIP for calling. As it is a software phone, it is known as a Softphone. Certainly, it uses VoIP technology to initiate and conduct calls to provide cheap calling and other VoIP communication advantages. However, it does not restrict users from making calls to other VoIP Softphone or virtual numbers only. In fact, you can make calls to any number, including traditional PSTN numbers, mobile numbers, DID numbers, etc.</p>
                            </div>
                        </div>
                        <div className="faq-set">
                            <h4>2. Who can benefit from a white label VoIP Softphone? <i className="fas fa-angle-down"></i></h4>
                            <div className="content" >
                                <p>Certainly, any business can benefit by using a white label Softphone. However, there are some users or companies that can leverage maximum advantages such as:</p>
                                <ul>
                                    <li>VoIP service providers</li>
                                    <li>ITSPs (Internet Telephony Service Providers)</li>
                                    <li>Communication service providers</li>
                                    <li>Telephony service providers</li>
                                    <li>UCaaS companies (Unified Communications as a Service)</li>
                                    <li>SaaS companies (Software as a Service)</li>
                                    <li>Technology product sellers</li>
                                </ul>
                            </div>
                        </div>
                        <div className="faq-set">
                            <h4>3. What type of customization service do you offer? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>Fonimo is a highly flexible and customizable Softphone app. We provide theme customization, skin development, custom branding, custom development, API integration, feature customization, and several other personalization services to provide a completely tailored Softphone app to you.</p>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </section>

    <Contact heading="Do you have any custom requirements and unique thoughts to discuss?" />
    </>
  )
}

export default CustomSoftphone
import React, {useState, useEffect} from 'react';
import { BackendURL, API_TOKEN } from '../common/Misc';

const Navbar = () => {

  const [globalData, setGlobalData] = useState(null);
  const [menuData, setMenuData] = useState(null);

  var menu_id = '';

  const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };

  useEffect(() => {
    fetch(BackendURL+process.env.REACT_APP_GLOBAL_API, { headers })
        .then(response => response.json())
        .then(data => setGlobalData(data.data));      
  }, []);

  if(globalData != null){
    menu_id = globalData.attributes.menu.data.id;
  }
  
  useEffect(() => {
        fetch(`${BackendURL}/api/menus/${menu_id}?populate=*`, { headers })
        .then(response => response.json())
        .then(data => setMenuData(data.data));        
  }, [menu_id]);

    
  return (
    <>
      {/* <div className='top-header p-2'>
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-lg-5 text-center'>
              <p className='m-0 text-white'>Meet Us at AfricaCom Expo︱Cape Town︱11th - 14th November 2024</p>
            </div>
            <div className='col-lg-2 text-center'>
              <a href='/africacom-2024' className='btn btn-secondary-gradient rounded-pill py-2 px-4'>Book a Meeting</a>
            </div>
          </div>
        </div>
      </div>     */}
      <header id='header-main'>
        <div className="container">
        {globalData && menuData ?
          <div className='main-header'>
            {/* <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                <a href="/" className="navbar-brand p-0">
                    <img width={164} height={45} className="logo" src={BackendURL+globalData.attributes.primary_logo.data.attributes.url} alt="Logo" />
                    <img width={164} height={45} className="sticky-logo" src={BackendURL+globalData.attributes.secondary_logo.data.attributes.url} alt="Logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav mx-auto py-0">
                      <ul id="header-menu" className="menu">
                        <li className="menu-item childrens">
                            <a href="#" className="nav-item nav-link">Solutions</a>
                            <ul className="sub-menu">
                              <li className="menu-item"><a href="/white-label-softphone">White Label Softphone</a></li>
                              <li className="menu-item"><a href="/custom-voip-softphone">Custom Softphone</a></li>
                            </ul>
                        </li>
                        <li className="menu-item">
                          <a href="/partners" className="nav-item nav-link">Partners</a>
                        </li>
                        <li className="menu-item">
                          <a href="/roadmap" className="nav-item nav-link">Roadmap</a>
                        </li>
                        <li className="menu-item">
                          <a href="/blog" className="nav-item nav-link">Blogs</a>
                        </li>
                      </ul>
                    </div>
                    
                    {menuData.map(({attributes,id}) => {
                      return (<div className="navbar-nav mx-auto py-0" key={id}> <ul id='header-menu' className='menu'>
                      {attributes.links.map((item) => (
                        <li className='menu-item'><a key={item.id} href={`/${item.url}`} className="nav-item nav-link">{item.label}</a></li>
                      ))}
                      <a href="/blog" className="nav-item nav-link">Blog</a>
                      </ul></div>)
                    })}
                    
                    <a href={globalData.attributes.header_cta.url} id="contact-btn" className="btn btn-secondary-gradient rounded-pill py-2 px-4 ms-3 d-none d-lg-block">{globalData.attributes.header_cta.label}</a>
                </div>
            </nav> */}

            <nav className="navbar navbar-expand-lg navbar-light bg-body-tertiary py-4">
              <div className="container-fluid">
                <a href="/" className="navbar-brand p-0">
                    <img width={164} height={45} className="logo" src={BackendURL+globalData.attributes.primary_logo.data.attributes.url} alt="Logo" />
                    <img width={164} height={45} className="sticky-logo" src={BackendURL+globalData.attributes.secondary_logo.data.attributes.url} alt="Logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <div className='navbar-nav mx-auto py-0'>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Solutions
                        </a>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="/white-label-softphone">White Label Softphone</a></li>
                          <li><a className="dropdown-item" href="/custom-voip-softphone">Custom Softphone</a></li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/partners">Partners</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/roadmap">Roadmap</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/blog">Blogs</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/pricing">Pricing</a>
                      </li>
                    </ul>
                  </div>
                  <a href={globalData.attributes.header_cta.url} id="contact-btn" className="btn btn-secondary-gradient rounded-pill py-2 px-4 ms-3 d-none d-lg-block">{globalData.attributes.header_cta.label}</a>
                </div>
              </div>
            </nav>
          </div>
          : ""}
        </div>
      </header>

    
    </>
  )
}

export default Navbar